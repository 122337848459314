<!-- Contact Component -->
<script>
  import { Col, Row, Container, FormGroup, Input } from "sveltestrap";
  function handleOnSubmit(e) {
    const formData = new FormData(e.target);

    const data = {};
    for (let field of formData) {
      const [key, value] = field;
      data[key] = value;
    }

    fetch('https://burglars-nightmare.web.app/api/send', {
      method: 'post',
      body: JSON.stringify(data)
    }).then(function(response) {
      e.target.reset();
      return response;
    });

	}
</script>

<!-- CONTACT FORM START-->
<section class="section common-section" id="contact">
  <Container>
    <Row>
      <Col lg={{ size: 8, offset: 2 }}>
        <h1 class="section-title text-center">Contact Us</h1>
        <div class="section-title-border mt-3" />
        <p class="section-subtitle text-muted text-center font-secondary pt-4">
          We are standing by and will reach out as soon as possible. We are open 24 hours a day.
        </p>
      </Col>
    </Row>
    <Row>
      <Col lg="4">
        <div class="mt-4 pt-4">
          <p class="mt-4">
            <span class="h5">Address:</span><br />
            <span class="text-muted d-block mt-2"
              >212 Outlet Way <br /> In the WOW Outlet <br /> Greensburg, PA 15601</span
            >
          </p>
          <p class="mt-4">
            <span class="h5">Phone:</span><br />
            <span class="text-muted d-block mt-2"
              >(724) 837-8284</span
            >
          </p>
          <p class="mt-4">
            <span class="h5">Business Hours:</span><br />
            <span class="text-muted d-block mt-2">Open 24 hours</span>
          </p>
        </div>
      </Col>
      <Col lg="8">
        <div class="custom-form mt-4 pt-4">
          <form method="post" name="myForm" on:submit|preventDefault={handleOnSubmit}>
            <p id="error-msg" />
            <div id="simple-msg" />
            <Row>
              <Col lg="4">
                <FormGroup class="mt-2">
                  <Input
                    name="name"
                    id="name"
                    type="text"
                    class="form-control"
                    placeholder="Your name*"
                    required
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup class="mt-2">
                  <Input
                    name="email"
                    id="email"
                    type="email"
                    class="form-control"
                    placeholder="Your email*"
                    required
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup class="mt-2">
                  <Input
                    name="phone"
                    id="phone"
                    type="tel"
                    class="form-control"
                    placeholder="Your phone number"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup class="mt-2">
                  <Input
                    type="textarea"
                    name="comments"
                    id="comments"
                    rows="4"
                    class="form-control"
                    placeholder="Your message..."
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12" class="text-end">
                <Input
                  type="submit"
                  id="submit"
                  name="send"
                  class="submitBnt btn btn-primary"
                  value="Send Message"
                />
              </Col>
            </Row>
          </form>
        </div>
      </Col>
    </Row>
  </Container>
</section>
<!-- CONTACT FORM END-->

<!--START SOCIAL-->
<section class="contact-social bg-light">
  <Container>
    <Row class="align-items-center">
      <!-- <Col lg="6">
        <ul class="list-inline social mt-4">
          <li class="list-inline-item">
            <a href={"#"} class="social-icon"><i class="mdi mdi-facebook" /></a>
          </li>
          <li class="list-inline-item">
            <a href={"#"} class="social-icon"><i class="mdi mdi-twitter" /></a>
          </li>
          <li class="list-inline-item">
            <a href={"#"} class="social-icon"><i class="mdi mdi-linkedin" /></a>
          </li>
          <li class="list-inline-item">
            <a href={"#"} class="social-icon"
              ><i class="mdi mdi-google-plus" /></a
            >
          </li>
          <li class="list-inline-item">
            <a href={"#"} class="social-icon"><i class="mdi mdi-dribbble" /></a>
          </li>
        </ul>
      </Col> -->
      <Col lg="6" class="mt-6">
        <p class="contact-title">
          <i class="pe-7s-call" /> &nbsp; (724) 837-8284
        </p>
      </Col>
      <Col lg="6" class="mt-6 text-end">
        <p class="contact-title">
          <i class="pe-7s-mail-open" />&nbsp; burglarsnightmare@gmail.com
        </p>
      </Col>
    </Row>
  </Container>
</section>
<!--END SOCIAL-->
