const SERVICE_DATA = {
    heading: "Our Services",
    description: "For over 20 years, we've put the people first and wouldn't trade a second. Whether you need an extra key copied for the house, or to get the heck back inside of it, we are ready for the job. Never a job too big or too small, so feel free to give a call anytime!",
    services_list: [{
        label: "Residential",
        text: "We'll come to you for all of your residential needs.",
        icon: "pe-7s-home"
    },
    {
        label: "Commercial",
        text: "We'll work with you, contact us for details.",
        icon: "pe-7s-culture"
    },
    {
        label: "Automotive",
        text: "We cut and program most Makes and Models! Have only one car key? We sell FOB and proximity remotes. Have you lost all your vehicle keys? Our mobile unit can come to you and get you up and running in no time!",
        icon: "pe-7s-car"
    }
    ]
};

const FEATURES_DATA = {
    title: 'A digital web design studio creating modern & engaging online experiences',
    text: 'Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia.',
    feature_list: [
        'We put a lot of effort in design.',
        'The most important ingredient of successful website.',
        'Sed ut perspiciatis unde omnis iste natus error sit.',
        'Submit Your Orgnization.'
    ]
};

const PRICING_DATA = {
    title: 'Our Pricing',
    description: 'Call to action pricing table is really crucial to your for your business website. Make your bids stand-out with amazing options.',
    pricing_list: [
        {
            title: 'Economy',
            price: '$9.90',
            bandwidth: '1GB',
            space: '50MB',
            support: 'No',
            domain: 1
        },
        {
            title: 'Delux',
            price: '$19.90',
            bandwidth: '10GB',
            space: '500MB',
            support: 'Yes',
            domain: 10
        },
        {
            title: 'Unlimate',
            price: '$29.90',
            bandwidth: '100GB',
            space: '2 GB',
            support: 'Yes',
            domain: 'Unlimited'
        }
    ]
};

const TEAM_DATA = {
    title: 'Behind The People',
    description: 'It is a long established fact that create category leading brand experiences a reader will be distracted by the readable content of a page when looking at its layout.',
    team_list: [
        {
            profile: '/images/team/img-1.jpg',
            name: 'Frank Johnson',
            designation: 'CEO'
        },
        {
            profile: '/images/team/img-2.jpg',
            name: 'Elaine Stclair',
            designation: 'Designer'
        },
        {
            profile: '/images/team/img-3.jpg',
            name: 'Wanda Arthur',
            designation: 'Developer'
        },
        {
            profile: '/images/team/img-4.jpg',
            name: 'Joshua Stemple',
            designation: 'Manager'
        }
    ]
}

const TESATIMONIAL_DATA = {
    title: "What they've said",
    description: "",
    testimonial_list: [
        {
            // profile: 'images/testimonials/user-2.jpg',
            description: "Very helpful! I had lost both of my keys to my vehicle and could not even use it. He came out as promptly as possible and reprogrammed a new key quickly and efficiently. I had already called R&G Locksmithing and they sent someone out that didn’t know what they were doing and they drained the battery in my van. However, this locksmith was helpful and fixed my key issue without any problems. I highly recommend.",
            name: 'Jacob J. - ',
            text: 'a week ago'
        },
        {
            // profile: 'images/testimonials/user-1.jpg',
            description: "Fast, professional and skilled locksmith. Our whole family (including 6 month old baby) got locked out of our house on a holiday weekend. Thought it might take hours for someone to show up given the holiday. Ron showed up within 15 minutes of our call and was able to quickly get us back into our home. We tried paying him extra for our appreciation and he would not accept. Bonus points for being so kind to our kids. I would recommend them to anyone.",
            name: 'Eva M. - ',
            text: 'a month ago'
        },
        {
            // profile: 'images/testimonials/user-3.jpg',
            description: "Awesome service! He came as soon as I called! I will definitely be using him again if ever needed!",
            name: 'Ellie Y. - ',
            text: 'a week ago'
        }
    ]
}

const BLOG_DATA = {
    title: 'Blog',
    description: 'Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean class at a euismod mus ipsum vel ex finibus semper luctus quam.',
    blog_list: [
        {
            image: 'images/blog/img-1.jpg',
            text: 'UI & UX Design',
            title: 'Doing a cross country road trip',
            subtext: 'She packed her seven versalia, put her initial into the belt and made herself on the way..'
        },
        {
            image: 'images/blog/img-2.jpg',
            text: 'Digital Marketing',
            title: 'New exhibition at our Museum',
            subtext: 'Pityful a rethoric question ran over her cheek, then she continued her way.'
        },
        {
            image: 'images/blog/img-3.jpg',
            text: 'Travelling',
            title: 'Why are so many people..',
            subtext: 'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia.'
        }
    ]
}

const MOCK_DATA = {
    SERVICE_DATA,
    FEATURES_DATA,
    PRICING_DATA,
    TEAM_DATA,
    TESATIMONIAL_DATA,
    BLOG_DATA
}

export default MOCK_DATA;