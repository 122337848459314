<script>
  import { Button } from "sveltestrap";
  import Navbar from "../Components/Navbar.svelte";

  import Services from "../Components/Services.svelte";
  // import Features from "../Components/Features.svelte";
  // import Pricing from "../Components/Pricing.svelte";
  // import Team from "../Components/Team.svelte";
  import Testimonial from "../Components/Testimonial.svelte";
  // import Blog from "../Components/Blog.svelte";
  import Contact from "../Components/Contact.svelte";
  // import Footer from "../Components/Footer.svelte";

  import data from "../Data/data";

  let open = false;
  /**
   * Toggle modal
   */
  const toggle = () => {
    open = !open;
  };

</script>

<Navbar />

<section
  class="section bg-home home-half active common-section"
  id="home"
  data-image-src="images/bg-home-2.jpg"
>
  <div class="bg-overlay" />
  <div class="display-table">
    <div class="display-table-cell">
      <div class="container">
        <div class="row justify-content-center">
          <Button
          class="btn btn-primary navbar-btn btn-rounded waves-effect waves-light"
          href="tel:7248378284"
          >Call Now! 724-837-8284</Button>
          <div class="col-lg-8 text-white text-center">
            <h1 class="pt-2 home-title">Burglar's Nightmare Locksmith</h1>
            <p class="pt-2 home-desc">Experienced Locksmiths serving Westmoreland County for over 25 years!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-pattern-effect">
    <img src="images/bg-pattern.png" alt="" />
  </div>
</section>


<Services serviceData={data.SERVICE_DATA} />
<!-- <Features featureData={data.FEATURES_DATA} />
<Pricing pricingData={data.PRICING_DATA} />
<Team teamData={data.TEAM_DATA} /> -->
<Testimonial testimonialData={data.TESATIMONIAL_DATA} />
<!-- <Blog blogData={data.BLOG_DATA} /> -->
<Contact />
<!-- <Footer /> -->